var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"hcM8lg1BGqMme1yQwUqJ-"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/app";

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  maxBreadcrumbs: 10,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  relsease:1.0,
  beforeBreadcrumb(breadcrumbs,hint){
    if(breadcrumbs.category === "xhr" || breadcrumbs.category === "fetch"){
      return null
    }
    return breadcrumbs;
  }
})